import store from '@/store'
import { CLEAR_PROFILE, SET_BACK_TO_LOGIN } from '@/store/actions'
import axios, { AxiosRequestConfig } from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import { shouldUpdateClientVersion } from 'smartbarcode-web-core/src/utils/helpers'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import {
  IAddress,
  IBarcodeImportRequestPayload,
  IBarcodeImportSearchPayload,
  IBarcodeSearch,
  IBarcodeSearchAutoCompleteRequest,
  IBarcodeSearchForm,
  ICarrierUserGroup,
  IChartOption,
  IClient,
  IClientUser,
  ICreateUserGroupForm,
  IDownloadWebclipPayload,
  IEnterprise,
  IEnterpriseUser,
  IExportPaginationParams,
  IIpRange,
  ILocationPayload,
  ILocationPermissionPayload,
  ILoginPayload,
  INewPasswordPayload,
  IPaginationPayload,
  IProduct,
  IProject,
  IProjectLocationPayload,
  IProjectUserGroup,
  IPublishProject,
  IRoutePath,
  IStaff,
  ITimetableUpdateNode,
  IUserGroupMergeData,
} from 'smartbarcode-web-core/src/utils/types/index'
import { version } from '../../package.json'
import { IImpersonateUserPayload, IVisitor } from './apiPayloadInterface'
import { LOCAL_STORAGE_ITEM, PROJECT_NEW_KEY_ID } from './constants'
import { getVisitorId } from './helpers'
import { getCurrentUtcOffset } from './timeUtils'
import { SB_FORCE_UPGRADE_VERSION_KEY, SB_PORTAL_VERSION_KEY } from 'smartbarcode-web-core/src/utils/constants'

export const baseUrl = `${process.env.VUE_APP_API_HOST}`

const axiosInstance = axios.create({
  baseURL: baseUrl,
  timeout: 60_000,
  withCredentials: true,
})

export async function refreshToken() {
  const payload = { visitorId: await getVisitorId() }
  return await axiosInstance.post('/user/refresh-token', payload)
}

axiosInstance.interceptors.request.use((config) => {
  if (config.headers) {
    config.headers['UTC-Offset'] = getCurrentUtcOffset()
    config.headers![SB_PORTAL_VERSION_KEY] = version
  }
  return config
})

function checkVersion(sbVersion: string | undefined, forceUpgrade: string | undefined) {
  if (sbVersion === null) return
  const clientVersion = version

  // Since value of headers are string we need to parse to boolean
  const isForceUpgrade = forceUpgrade?.toLowerCase() === 'true'

  if (sbVersion) {
    if (shouldUpdateClientVersion(clientVersion, sbVersion)) {
      document.dispatchEvent(new CustomEvent('onUpdateVersionNotification', { detail: { isForceUpgrade } }))
    }
  }
}

axiosInstance.interceptors.response.use(
  (response) => {
    try {
      const sbVersion = response?.headers?.[SB_PORTAL_VERSION_KEY]
      const isForceUpgrade = response?.headers?.[SB_FORCE_UPGRADE_VERSION_KEY]
      checkVersion(sbVersion, isForceUpgrade)
    } catch (e) {}
    return response
  },
  async (error) => {
    if (error?.message === 'Network Error') {
      // TODO: resolve network error when not get data from server
    }
    // Revisit this when refactoring common error code from API
    // const title = error.response?.data?.title || ''
    // const errors = { ...error.response?.data?.errors, title }
    const errors = error.response?.data?.errors
    const sbVersion = error?.response?.headers?.[SB_PORTAL_VERSION_KEY]
    const isForceUpgrade = error?.response?.headers?.[SB_FORCE_UPGRADE_VERSION_KEY]
    checkVersion(sbVersion, isForceUpgrade)
    switch (error?.response?.status) {
      case 400:
        if (errors?.$common) {
          throw errors.$common[0]
        } else {
          throw errors || error.response?.data
        }

      case 401: {
        const originalRequest = error.config
        try {
          await refreshToken()
          if (!isEmpty(originalRequest.data)) {
            originalRequest.data = JSON.parse(originalRequest.data)
          }

          return axiosInstance(originalRequest)
        } catch (err) {
          store.commit(CLEAR_PROFILE)
          store.dispatch(SET_BACK_TO_LOGIN)
        }
        break
      }
      case 403:
        throw new Error('403')

      case 500:
        throw new Error('500')

      default:
        throw new Error(error?.message)
    }
  }
)

export async function login(payload: ILoginPayload) {
  payload.visitorId = await getVisitorId()
  return await axiosInstance.post('/user/login', payload)
}

export async function logout() {
  const payload = { visitorId: await getVisitorId() }
  localStorage.removeItem(LOCAL_STORAGE_ITEM.VISITOR_ID)
  await axiosInstance.post('/user/logout', payload)
}

export async function getProfile() {
  const response = await axiosInstance.get('/user/profile')
  return response?.data
}

export async function sendResetPasswordRequest(email: string) {
  return await axiosInstance.post('/user/reset-password', { email })
}

export async function setNewPassword(payload: INewPasswordPayload) {
  payload.visitorId = await getVisitorId()
  return await axiosInstance.post('user/new-password', payload)
}

export async function getRecycleHistory(id: string, skip: number, count: number) {
  const response = await axiosInstance.get('/barcode/history', { params: { id, skip, count } })
  return response?.data
}

export async function getCountries() {
  const response = await axiosInstance.get('/user/countries')
  return response?.data
}

export async function uploadTimetableCsv(payload: FormData, uploadProgressCallback: Function) {
  const response = await axiosInstance.post('/timetable/timetable', payload, {
    onUploadProgress: (event: ProgressEvent) => uploadProgressCallback(event),
  })
  return response?.data
}

export async function getTimetable(date: string) {
  const response = await axiosInstance.get('/timetable/timetable', {
    params: { date },
  })

  return response?.data
}

export async function getTimetableSchedule(timetableId: string) {
  const response = await axiosInstance.get('/timetable/schedule', {
    params: {
      timetableId,
    },
  })

  return response?.data
}

export async function acknowledgeTimetable(payload: ITimetableUpdateNode) {
  const response = await axiosInstance.post('timetable/ack', payload)
  return response?.data
}

export async function updateTimeTable(payload: ITimetableUpdateNode) {
  const response = await axiosInstance.put('/timetable/time', payload)

  return response?.data
}

export async function deleteTimeTable(id: string) {
  const response = await axiosInstance.delete('/timetable/timetable', {
    data: {
      timetableId: id,
    },
  })

  return response?.data
}

export async function getProjectsList(latestVersionOnly = true, includeDrafts = true) {
  const response = await axiosInstance.get('/barcode/projects', {
    params: { latestVersionOnly, includeDrafts },
  })
  return response?.data
}

export async function getActiveProjectsList(isFollowCurrentUser = true) {
  const response = await axiosInstance.get('/barcode/all-projects', {
    params: { isFollowCurrentUser },
  })
  return response?.data
}

export async function fetchProject(code: string, version?: string) {
  const response = await axiosInstance.get('/barcode/project', {
    params: {
      code,
      ...(version && { version }),
    },
  })
  return response?.data?.project
}

export async function fetchDraftProject(projectDraftId: string) {
  const response = await axiosInstance.get('/barcode/project-draft', {
    params: {
      projectDraftId,
    },
  })
  return response?.data
}

export async function fetchProjectById(id: string, version?: string) {
  const response = await axiosInstance.get('/barcode/project', {
    params: {
      id,
      ...(version && { version }),
    },
  })
  return response?.data
}

export async function getUserGroups() {
  const response = await axiosInstance.get('/user/user-groups')
  return response?.data
}

export async function getUserGroupByProjectId(projectId: string) {
  const response = await axiosInstance.get('/user/user-groups', {
    params: {
      projectId,
    },
  })
  return response?.data
}

export async function getUserGroup(userGroupId: string) {
  const response = await axiosInstance.get('/user/user-group', {
    params: {
      userGroupId,
    },
  })
  return response?.data
}

export async function exportBarCode(payload: Record<string, unknown>) {
  const result = await axiosInstance.post('/barcode/barcode-export', payload)
  return result?.data
}

export async function exportBarCodeAttchFiles(payload: Record<string, unknown>) {
  const result = await axiosInstance.post('/barcode/barcode-download-file', payload)
  return result?.data
}

export async function deleteDraftProject(projectDraftId: string) {
  const response = await axiosInstance.delete('/barcode/project-draft', {
    data: {
      projectDraftId,
    },
  })

  return response?.data
}

export async function publishDraftProject(
  newObj: IProject,
  permissionGroup: IProjectUserGroup[],
  locations: IProjectLocationPayload[]
) {
  if (newObj.isDraft) {
    const objProject = {
      projectDraftId: newObj.id,
      name: newObj.name,
      allowUnmatchedTracking: newObj.allowUnmatchedTracking,
      showBarcodeSearchPage: newObj.showBarcodeSearchPage,
      showTrackingCountPage: newObj.showTrackingCountPage,
      isAutoRecycleEndTrackingPoint: newObj.isAutoRecycleEndTrackingPoint,
      isRecycleChildren: newObj.isRecycleChildren,
      allowForcePairing: newObj.allowForcePairing,
      googleAnalyticsMeasurementId: newObj.googleAnalyticsMeasurementId,
      barcodeTypes: newObj.barcodeTypes,
      trackPoints: newObj.trackPoints,
      userGroups: permissionGroup,
      locations,
    } as IPublishProject

    const result = await axiosInstance.put('/barcode/project-draft', objProject)
    return result?.data
  } else {
    const objProject = {
      targetProjectId: newObj?.version ? newObj.id : '',
      name: newObj.name,
      code: newObj.code,
      allowUnmatchedTracking: newObj.allowUnmatchedTracking,
      showBarcodeSearchPage: newObj.showBarcodeSearchPage,
      showTrackingCountPage: newObj.showTrackingCountPage,
      isAutoRecycleEndTrackingPoint: newObj.isAutoRecycleEndTrackingPoint,
      isRecycleChildren: newObj.isRecycleChildren,
      allowForcePairing: newObj.allowForcePairing,
      googleAnalyticsMeasurementId: newObj.googleAnalyticsMeasurementId,
      barcodeTypes: newObj.barcodeTypes,
      trackPoints: newObj.trackPoints,
      userGroups: permissionGroup,
      locations,
    }

    const result = await axiosInstance.post('/barcode/project-draft', objProject)
    return result?.data
  }
}

export async function publishProject(
  newObj: IProject,
  userGroups: IProjectUserGroup[],
  locations: IProjectLocationPayload[],
  isDraft: boolean,
  isUpdateVersion: boolean,
  isDryRun?: boolean
) {
  const objProject = {
    name: newObj.name,
    showBarcodeSearchPage: newObj.showBarcodeSearchPage,
    showTrackingCountPage: newObj.showTrackingCountPage,
    isAutoRecycleEndTrackingPoint: newObj.isAutoRecycleEndTrackingPoint,
    isRecycleChildren: newObj.isRecycleChildren,
    allowUnmatchedTracking: newObj.allowUnmatchedTracking,
    allowForcePairing: newObj.allowForcePairing,
    googleAnalyticsMeasurementId: newObj.googleAnalyticsMeasurementId,
    barcodeTypes: newObj.barcodeTypes,
    trackPoints: newObj.trackPoints,
    userGroups: userGroups,
    locations,
    ...(isDryRun !== undefined && { isDryRun }),
  } as IPublishProject

  let projectId = ''
  // Publish draft project is already exist from another project
  if (isDraft) {
    objProject.projectDraftId = newObj.id
    if (newObj.targetProjectId) projectId = newObj.targetProjectId
    else objProject.code = newObj.code
  } else {
    if (newObj?.id) projectId = newObj.id
    else objProject.code = newObj.code
  }

  if (projectId) {
    const data = {
      projectId,
      ...objProject,
      updateVersion: isUpdateVersion,
    }
    const result = await axiosInstance.put('/barcode/project', data)
    return result?.data
  } else {
    const result = await axiosInstance.post('/barcode/project', objProject)
    return result?.data
  }
}

export async function validateProjectUpdate(
  newObj: IProject,
  userGroups: IProjectUserGroup[],
  locations: IProjectLocationPayload[],
  isDraft: boolean
) {
  return await publishProject(newObj, userGroups, locations, isDraft, false, true)
}

export async function addUserGroup(userGroup: ICarrierUserGroup) {
  await axiosInstance.post('/user/user-group', {
    userIds: userGroup.userIds,
    name: userGroup.name,
    projectPermissions: userGroup.projectPermissions,
  })
}

export async function updateUserGroup(userGroup: ICarrierUserGroup) {
  await axiosInstance.put('/user/user-group', {
    userGroupId: userGroup.id,
    isActive: userGroup.isActive,
    userIds: userGroup.userIds,
    name: userGroup.name,
    projectPermissions: userGroup.projectPermissions,
  })
}

export async function mergeUserGroups(data: IUserGroupMergeData) {
  await axiosInstance.post('/user/user-group/merge', data)
}

export async function updateUserGroups(groups: ICreateUserGroupForm[], newProjectId: string, oldProjectId: string) {
  const updatedData = cloneDeep(groups)
  updatedData.forEach(async (element: ICreateUserGroupForm) => {
    if (!element.projectPermissions) return

    const newPermission = cloneDeep(element.projectPermissions[oldProjectId])
    delete element.projectPermissions[oldProjectId]
    delete element.projectPermissions[PROJECT_NEW_KEY_ID]

    if (element.isNewGroup || (!element?.id && !element.userGroupId)) {
      await axiosInstance.post('/user/user-group', {
        userIds: element.userIds,
        name: element.name,
        projectPermissions: {
          [newProjectId]: {
            ...newPermission,
          },
        },
      })
    } else {
      await axiosInstance.put('/user/user-group', {
        userGroupId: element?.id || element?.userGroupId,
        isActive: true,
        userIds: element.userIds,
        name: element.name,
        projectPermissions: {
          ...element?.projectPermissions,
          [newProjectId]: {
            ...newPermission,
          },
        },
      })
    }
  })
}

export async function fetchStaff() {
  const response = await axiosInstance.get('/user/carrier-users')
  return response?.data
}

export async function fetchStaffById(userId: string) {
  const response = await axiosInstance.get('/user/carrier-user', {
    params: {
      userId,
    },
  })
  return response?.data
}

export async function createNewStaff(staff: IStaff) {
  await axiosInstance.post('/user/carrier-user', staff)
}

export async function updateStaff(staff: unknown) {
  const response = await axiosInstance.put('/user/carrier-user', staff)
  return response?.data
}
export async function getClientList() {
  const response = await axiosInstance.get('/user/clients')
  return response?.data
}

export async function getClient(clientId: string) {
  const response = await axiosInstance.get('/user/client', {
    params: {
      clientId,
    },
  })
  return response?.data
}

/** LOCATION */
export async function getBarcodeAttachedFileList(payload: IPaginationPayload & { isSummarize?: boolean }) {
  const response = await axiosInstance.get('/barcode/barcode-download-files', { params: payload })
  return response?.data
}

/* ******** */

/** LOCATION */
export async function getLocationList(payload: IPaginationPayload & { isSummarize?: boolean }) {
  const response = await axiosInstance.get('/location/locations', { params: payload })
  return response?.data
}

export async function getLocation(locationId: string) {
  const response = await axiosInstance.get('/location/location', { params: { locationId } })
  return response?.data
}

export async function createLocation(location: ILocationPayload) {
  const response = await axiosInstance.post('/location/location', location)
  return response?.data
}
export async function updateLocation(location: ILocationPayload) {
  const response = await axiosInstance.put('/location/location', location)
  return response?.data
}

export async function getLocationPermissions(data: ILocationPermissionPayload) {
  const response = await axiosInstance.get('/location/location-projects', { params: { ...data } })
  return response?.data
}

export async function getProjectLocationsByProjectId(projectId: string) {
  const response = await axiosInstance.get('/location/project-locations', { params: { projectId } })
  return response?.data
}

export async function exportLocation(locationId: string) {
  const result = await axiosInstance.post('/location/location-export', { locationId })
  return result?.data
}

export async function getLocationExportList(payload: IPaginationPayload) {
  const result = await axiosInstance.get('/location/location-exports', { params: payload })
  return result?.data
}

/* ******** */

export async function saveClient(newObj: IClient, isEditPassword: boolean) {
  const { isEdit, ...restObj } = newObj
  if (!isEdit) {
    const result = await axiosInstance.post('/user/client', restObj)
    return result?.data
  } else {
    const { clientUser, ...editObj } = restObj
    const resultClient = await axiosInstance.put('/user/client', editObj)

    const { id, ...restClientUser } = clientUser
    if (!restClientUser.password) {
      restClientUser.password = ''
    }
    const clientUserData = {
      ...restClientUser,
      userId: id,
      isActive: editObj.isActive,
      isEditPassword,
    } as IClientUser

    await axiosInstance.put('/user/client-user', clientUserData)
    return resultClient?.data
  }
}

export async function getEnterpriseList() {
  const response = await axiosInstance.get('/user/enterprise-users')
  return response?.data
}

export async function getEnterprise(userId: string) {
  const response = await axiosInstance.get('/user/enterprise-user', {
    params: {
      userId,
    },
  })
  return response?.data
}

export async function saveEnterprise(newObj: IEnterprise, isEditPassword: boolean) {
  const { isEdit, isActive, ...restObj } = newObj
  if (!isEdit) {
    const result = await axiosInstance.post('/user/enterprise-user', restObj)
    return result?.data
  } else {
    const { id, ...editObj } = restObj
    if (!editObj.password) {
      editObj.password = ''
    }
    const newDataFormat = {
      ...editObj,
      userId: id,
      isActive: isActive,
      isEditPassword: isEditPassword,
    } as IEnterpriseUser

    const result = await axiosInstance.put('/user/enterprise-user', newDataFormat)
    return result?.data
  }
}
// ====== ADDRESS ======
export async function getAddressDetail(addressIndex: string) {
  const response = await axiosInstance.get('/user/address-detail', {
    params: {
      addressIndex,
    },
  })
  return response?.data
}

export async function getAddresses() {
  const response = await axiosInstance.get('/user/address')

  return response?.data
}

export async function addAddress(payload: IAddress) {
  return await axiosInstance.post('/user/address', { address: payload })
}

export async function updateAddress(idx: number, payload: IAddress) {
  return await axiosInstance.put('/user/address', { index: idx, address: payload })
}

export async function deleteAddress(idx: number) {
  return await axiosInstance.delete('/user/address', { data: { index: idx } })
}

// ====== PRODUCTS ======

export async function getProduct(productId: string) {
  const response = await axiosInstance.get('/barcode/product', {
    params: {
      id: productId,
    },
  })
  return response?.data
}

export async function getProducts() {
  const response = await axiosInstance.get('/barcode/products')

  return response?.data
}

export async function getProductSuppliers() {
  const response = await axiosInstance.get('/barcode/product-suppliers')

  return response?.data
}

export async function addProduct(payload: IProduct) {
  return await axiosInstance.post('/barcode/product', payload)
}

export async function updateProduct(productId: string, isActive: boolean, payload: IProduct) {
  const data = { ...payload, productId, isActive }
  return await axiosInstance.put('/barcode/product', data)
}

export async function getBarcodeSearchAutoComplete(data: IBarcodeSearchAutoCompleteRequest) {
  const response = await axiosInstance.post('/barcode/search/auto-complete', data)
  return response?.data
}

export async function getBarcodeList(data: IBarcodeSearch) {
  const response = await axiosInstance.post('/v2/barcode/search', data)
  return response?.data
}

export async function getCountBarcodeList(data: IBarcodeSearch) {
  const response = await axiosInstance.post('/v2/barcode/count', data)
  return response?.data
}

export async function getBarcode(id: string) {
  const response = await axiosInstance.get('/barcode/barcode', {
    params: {
      id,
    },
  })
  return response?.data
}

export async function getBarcodeByPath(id: string, path: string) {
  const response = await axiosInstance.get('/barcode/linked-barcode', {
    params: {
      id,
      path,
    },
  })
  return response?.data
}

export async function getBarcodeChildren(id: string) {
  const response = await axiosInstance.get('/barcode/children', {
    params: {
      id,
    },
  })
  return response?.data
}

export async function getUserList() {
  const response = await axiosInstance.get('/user/users')
  return response?.data
}

// ==== UPLOAD IMAGE ( project custom content) ====
export async function uploadImage(payload: unknown) {
  const response = await axiosInstance.post('user/enterprise/file', payload)
  return response?.data
}

export async function removeImage(filePath: string) {
  const response = await axiosInstance.delete('user/enterprise/file', { data: { filePath } })
  return response?.data
}

export async function getBarcodeImports(data: IBarcodeImportSearchPayload) {
  const response = await axiosInstance.get('/barcode/barcode-imports', {
    params: {
      projectCode: data.projectCode,
      skip: data.skip,
      count: data.count,
    },
  })
  return response?.data
}

export async function getBarcodeImport(payload: IBarcodeImportRequestPayload & { type: 'success' | 'error' }) {
  const response = await axiosInstance.get('/barcode/barcode-import', { params: payload })
  return response?.data
}

export async function getBarcodeCSVExample(projectId: string, barcodeType: string) {
  const response = await axiosInstance.get('/barcode/barcode-import/file/example', {
    params: {
      projectId,
      barcodeType,
    },
    responseType: 'blob',
  })
  return response?.data
}

export async function bulkPublish(projectId: string, count: number, clientIds: string[]) {
  const response = await axiosInstance.post('/barcode/barcode-import', {
    projectId,
    count,
    clientIds,
  })
  return response?.data
}

export async function uploadBarcodeImportCSV(payload: FormData, uploadProgressCallback: Function) {
  const response = await axiosInstance.post('/barcode/barcode-import/file', payload, {
    onUploadProgress: (event: ProgressEvent) => uploadProgressCallback(event),
  })
  return response?.data
}

export async function getBarcodeImportDetail(id: string) {
  const response = await axiosInstance.get('/barcode/barcode-import/barcodes', {
    params: {
      id,
    },
  })
  return response?.data
}

export async function reconcileBarcode(barcodeId: string, index: number) {
  const response = await axiosInstance.post('barcode/ledger/reconcile', {
    barcodeId,
    index,
  })
  return response?.data
}

export async function getBarcodeLedgerStatus(id: string, index: number) {
  const response = await axiosInstance.get('/barcode/ledger/status', {
    params: {
      id,
      index,
    },
  })
  return response?.data
}

export async function invokeExternalAPI(barcodeId: string) {
  return await axiosInstance.post('/barcode/external-api', { barcodeId }, { responseType: 'blob' })
}

/**
 * STATISTICS
 */
export async function getStatistics(payload: Record<string, string | string[]>) {
  // process the special case: DUPLICATED KEY in url params
  const searchParams = new URLSearchParams()
  Object.entries(payload).map(([key, value]) => {
    if (typeof value === 'string') {
      searchParams.append(key, value)
    } else {
      ;(value as string[]).forEach((k) => searchParams.append(key, k))
    }
  })

  return (await axiosInstance.get('/statistics/barcode/count', { params: searchParams }))?.data
}

export async function compileMessage(barcodeId: string, template: string) {
  return (await axiosInstance.post('barcode/compile-message', { barcodeId, template }))?.data
}

/** IMPERSONATE */
export async function impersonate(payload: IImpersonateUserPayload) {
  return await axiosInstance.post('user/impersonate', payload)
}

export async function unimpersonate(payload: IVisitor) {
  return await axiosInstance.post('user/unimpersonate', payload)
}

export async function uploadProjectAttachedFile(payload: unknown) {
  const response = await axiosInstance.post('/barcode/project/file', payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
  return response?.data
}

export async function getProjectAttachedFile(filePath: string) {
  const response = await axiosInstance.get('/barcode/project/file', { params: { filePath } })
  return response?.data
}

export async function requestBarcodeReport(barcodeId: string, code: string) {
  const response = await axiosInstance.post('/barcode/barcode-report', {
    barcodeId,
    reportTemplateCode: code,
  })
  return response?.data
}

export async function getBarcodeExport(dataSearch: IExportPaginationParams) {
  const response = await axiosInstance.get('/barcode/barcode-exports', { params: dataSearch })
  return response?.data
}

export async function getAccessLog(targetMonth: string /* yyyy-MM */) {
  const response = await axiosInstance.post('/system/log-export', { targetMonth })
  return response?.data
}

export async function getAccessLogOutput(dataSearch: IExportPaginationParams) {
  const response = await axiosInstance.get('/system/log-exports', { params: dataSearch })
  return response?.data
}

export async function downloadWebclip(payload: IDownloadWebclipPayload) {
  const response = await axiosInstance.post('/barcode/webclip-export', payload, { responseType: 'blob' })
  return response
}

export async function updateReferenceFieldBarcode(barcodeId: string) {
  const response = await axiosInstance.get('/barcode/reference-data', {
    params: { barcodeId },
  })
  return response?.data
}

export async function fetchCustomGraphs() {
  const response = await axiosInstance.get('/statistics/graphs')
  return response?.data
}
export async function createCustomGraph(chartOption?: IChartOption) {
  const response = await axiosInstance.post('/statistics/graph', chartOption)
  return response?.data
}

export async function editCustomGraph(chartOption: IChartOption) {
  const response = await axiosInstance.put('/statistics/graph', chartOption)
  return response?.data
}

export async function deleteCustomGraph(graphId: string) {
  const response = await axiosInstance.delete('/statistics/graph', {
    data: {
      graphId,
    },
  })
  return response?.data
}

export async function getExecutedGraph(graphId: string) {
  const response = await axiosInstance.get('/statistics/exec-graph', {
    params: { graphId },
  })
  return response?.data
}

export async function customRequest(payload: AxiosRequestConfig<unknown>) {
  return (await axiosInstance.request(payload)).data
}

export async function getTWProcessList() {
  return (await axiosInstance.get('/tradeWaltz/processed')).data
}

export async function activateTWProess(data: {
  entityId: string
  linkageGoodsLineBinding: IRoutePath[]
  linkageGoodsLinePackingBinding: IRoutePath[]
  linkageGoodsLinePriceBinding: IRoutePath[]
  bindings: IRoutePath[]
  startTrackingPointKey: string
  projectCode: string
  projectVersion: number
  isDryRun: boolean
}) {
  return (await axiosInstance.post('/tradeWaltz/activate-process', data))?.data
}

// =============== CLIENT SEARCH DATA ===================
export interface IClientSearchDataPayload {
  isConfirm: boolean
  clientIds: string[]
  condition: IBarcodeSearchForm
}

export async function setClientSearchData(data: IClientSearchDataPayload) {
  return (await axiosInstance.post('/user/client-search-data', data))?.data
}

export interface IArchivePayload {
  archiveType: 'archive' | 'unarchive'
  condition: IBarcodeSearchForm
}

export async function archiveBarcodes(data: IArchivePayload) {
  return (await axiosInstance.post('/barcode/archive', data))?.data
}

export async function getBarcodeArchiveProcessList(data: IExportPaginationParams) {
  return (await axiosInstance.get('/barcode/barcode-archives', { params: data }))?.data
}

export async function setOrganizationWhiteList(data: { whiteListIp: IIpRange[] }) {
  return (await axiosInstance.post('/organization/ipWhitelist', data))?.data
}

// =============== PAIRING, UNPAIRING ====================

export interface IPairRequestBody {
  isDryRun?: boolean
  parentBarcodeId: string
  childrenBarcodeIds?: Array<string>
  forcePairing?: boolean
}

export interface IUnpairRequest {
  isDryRun?: boolean
  parentBarcodeId: string
  isUnpairAll: boolean
  unpairChildrenIds?: Array<string>
}

export async function pairBarcode(request: IPairRequestBody) {
  const response = await axiosInstance.post('/barcode/pairing', request)
  return response?.data
}
export async function unpairBarcode(request: IUnpairRequest) {
  const response = await axiosInstance.post('/barcode/unpairing', request)
  return response?.data
}
