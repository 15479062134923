/* eslint-disable no-useless-catch */
import {
  fetchProject,
  fetchProjectById,
  getBarcode,
  getBarcodeByPath,
  getBarcodeChildren,
  getBarcodeList,
  getCountBarcodeList,
  getProduct,
} from '@/utils/api'
import { formatSearchConditionBlocks } from '@/utils/helpers'
import cloneDeep from 'lodash/cloneDeep'
import {
  IBarcodeDetail,
  IBarcodeList,
  IBarcodeResult,
  IBarcodeSearch,
  IBarcodeSearchForm,
  IProduct,
  IProject,
  ISearchConditionBlock,
} from 'smartbarcode-web-core/src/utils/types/index'
import {
  CLEAR_ALL,
  CLEAR_DETAIL_BARCODE,
  FETCH_BARCODE,
  FETCH_BARCODE_CHILDREN,
  LOAD_BARCODE_LIST,
  SAVE_BARCODE,
  UPDATE_BARCODE_LIST_COUNT,
} from './actions'

type TBarcodeStates = {
  isLoaded: boolean
  searchProjectCode: string
  searchProjectVersion: number
  totalBarcodes: number
  barcodes: Array<IBarcodeList>
  barcode: IBarcodeDetail
  projectList: IProject
  projectDetail: IProject
  product: IProduct
  parentId: string
  barcodeChildren: Array<IBarcodeList>
  dataSearch: IBarcodeSearchForm
}

export default {
  state: {
    isLoaded: false,
    searchProjectCode: '',
    searchProjectVersion: 0,
    totalBarcodes: 0,
    barcodes: [],
    barcode: {} as IBarcodeDetail,
    projectList: {} as IProject,
    projectDetail: {} as IProject,
    product: {} as IProduct,
    dataSearch: {} as IBarcodeSearchForm,
  },
  getters: {
    projectParam: (state: TBarcodeStates) => {
      if (state?.projectDetail) {
        return `${state.projectDetail.code}@${state.projectDetail.version}`
      }
      return ''
    },
  },
  mutations: {
    [LOAD_BARCODE_LIST]: (
      state: TBarcodeStates,
      {
        barcodes,
        totalBarcodes,
        dataSearch,
        project,
      }: {
        barcodes: Array<IBarcodeList>
        totalBarcodes: number
        dataSearch: IBarcodeSearchForm
        project?: IProject
      }
    ) => {
      state.isLoaded = true
      state.barcodes = barcodes
      state.totalBarcodes = totalBarcodes
      state.dataSearch = cloneDeep(dataSearch)
      if (project) {
        state.projectList = project
        state.searchProjectCode = project.code ?? ''
        state.searchProjectVersion = project.version ?? 0
      }
    },
    [UPDATE_BARCODE_LIST_COUNT]: (state: TBarcodeStates, { totalBarcodes }: { totalBarcodes: number }) => {
      state.totalBarcodes = totalBarcodes
    },
    [SAVE_BARCODE]: (
      state: TBarcodeStates,
      {
        barcode,
        project,
        product,
      }: {
        barcode: IBarcodeDetail
        project?: IProject
        product?: IProduct
      }
    ) => {
      state.barcode = { ...barcode }
      if (project) {
        state.projectDetail = project
      }
      if (product) {
        state.product = product
      }
    },
    [FETCH_BARCODE_CHILDREN]: (
      state: TBarcodeStates,
      {
        barcodeChildren,
        barcodeId,
      }: {
        barcodeChildren: Array<IBarcodeList>
        barcodeId: string
      }
    ) => {
      state.barcodeChildren = barcodeChildren
      state.parentId = barcodeId
    },
    [CLEAR_DETAIL_BARCODE]: (state: TBarcodeStates) => {
      state.barcode = {} as IBarcodeDetail
    },
    [CLEAR_ALL]: (state: TBarcodeStates) => {
      state.isLoaded = false
      state.searchProjectCode = ''
      state.barcodes = []
      state.barcode = {} as IBarcodeDetail
      state.projectList = {} as IProject
      state.projectDetail = {} as IProject
      state.product = {} as IProduct
    },
  },
  actions: {
    [LOAD_BARCODE_LIST]: async (
      {
        commit,
        state,
      }: {
        commit: Function
        state: TBarcodeStates
      },
      data: IBarcodeSearchForm
    ) => {
      try {
        let project

        if (
          !(
            state.projectList &&
            data.projectCode === state.searchProjectCode &&
            data.version === state.searchProjectVersion
          )
        ) {
          project = await fetchProject(data.projectCode, data.version === 0 ? undefined : String(data.version))
        } else {
          project = state.projectList
        }

        // format dataSearch
        const condBlocks = [] as ISearchConditionBlock[]
        data.searchConditionBlocks.forEach((i) => condBlocks.push(cloneDeep(i)))
        const dataSearch = {
          condition: {
            ...data,
            searchConditionBlocks: formatSearchConditionBlocks(condBlocks),
          },
        } as IBarcodeSearch
        let result = {} as IBarcodeResult
        try {
          result = { count: 0 /* dataSearch.condition.count */, results: await getBarcodeList(dataSearch) }
          // const isPerformCount =
          //   isEmpty(state.dataSearch) || !isEqual(omit(cloneDeep(state.dataSearch), 'skip'), omit(data, 'skip'))

          // if (isPerformCount) {
          const count = await getCountBarcodeList(dataSearch)
            .then((res) => {
              // if (res?.count === state.totalBarcodes) return
              // commit(UPDATE_BARCODE_LIST_COUNT, { totalBarcodes: res?.count ?? 0 })
              return res?.count ?? 0
            })
            .catch((err) => console.error('error', err))
          // } else {
          result.count = /** state.totalBarcodes */ count ?? dataSearch.condition.count
          // }
        } catch (e) {
          result = { count: 0, results: [] }
        }

        commit(LOAD_BARCODE_LIST, {
          barcodes: result.results,
          dataSearch: data,
          totalBarcodes: result.count,
          project: project,
        })
      } catch (err) {
        throw err
      }
    },
    [FETCH_BARCODE]: async (
      {
        commit,
        state,
      }: {
        commit: Function
        state: TBarcodeStates
      },
      data: {
        id: string
        path?: string
      }
    ) => {
      try {
        let response
        if (data.path) {
          response = (await getBarcodeByPath(data.id, data.path)) as IBarcodeDetail
        } else {
          response = (await getBarcode(data.id)) as IBarcodeDetail
        }

        let project
        if (!(state.projectDetail && response.projectId === state.projectDetail.id)) {
          const result = await fetchProjectById(response.projectId)
          project = result?.project
        }

        let product
        if (response.activationData?.productId) {
          const result = await getProduct(response.activationData?.productId)
          product = result
        }

        commit(SAVE_BARCODE, { barcode: response, project, product })
      } catch (err) {
        throw err
      }
    },
    [FETCH_BARCODE_CHILDREN]: async (
      {
        commit,
      }: {
        commit: Function
      },
      barcodeId: string
    ) => {
      try {
        const response = (await getBarcodeChildren(barcodeId)) as Array<IBarcodeList>

        commit(FETCH_BARCODE_CHILDREN, { barcodeChildren: response, barcodeId })
      } catch (err) {
        throw err
      }
    },
  },
}
